const createTask = async (formData, token) => {
    const apiUrl = `${process.env.REACT_APP_DOMAIN_URL}/api/task/create`;

    try {
        const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
                "Authorization": token
            },
            body: formData // Передача объекта FormData в качестве тела запроса
        });

        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            throw new Error(`Error creating task: ${response.statusText}`);
        }
    } catch (error) {
        console.error(`Error: ${error.message}`);
        throw error;
    }
};

export default createTask;
