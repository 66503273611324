import React, { useState, useEffect } from "react";
import "./FormSelect.css"; // Подключение стилей может потребовать дополнительной настройки
import { Link, useLocation } from "react-router-dom";

export const FormSelect = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    const selectElement = document.createElement("select");
    selectElement.style.display = "none";
    selectElement.id = props.name;
    selectElement.multiple = props.isMultiple;
    props.items.forEach((select_item, index) => {
      const optionElement = document.createElement("option");
      optionElement.value = select_item.value;
      optionElement.textContent = select_item.content;
      selectElement.appendChild(optionElement);
    });
    document.body.appendChild(selectElement);

    return () => {
      document.body.removeChild(selectElement);
    };
  }, [props.items, props.isMultiple, props.name, props.value]);

  const toggleSelect = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (index) => {
    if (!props.isMultiple) {
      setSelectedItems([index]);
    } else {
      const selectedIndex = selectedItems.indexOf(index);
      let newSelectedItems = [...selectedItems];
      if (selectedIndex !== -1) {
        newSelectedItems.splice(selectedIndex, 1);
      } else {
        newSelectedItems.push(index);
      }
      setSelectedItems(newSelectedItems);
    }
  };

  return (
    <div className={`select ${props.isMultiple ? 'multiple' : ''} ${isOpen ? 'opened' : ''}`}>
      <div className="select__header" onClick={toggleSelect}>
        <div>
          <label className="label" htmlFor={props.name + '-' + props.value}>{props.content}</label>
          <div className="select-heading">Выберите один или несколько вариантов</div>
        </div>
        <img src="img/fi_chevron-down.svg" alt="" />
      </div>
      <div className="select__body">
        <ul className="select-ul">
          {props.items.map((select_item, index) => (
            <li key={index} className={`select-ul__item ${selectedItems.includes(index) ? 'selected' : ''}`} onClick={() => handleItemClick(index)}>{select_item.content}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};
