import React, { useState, useEffect } from "react";
import getTasks from "../api/tasks/get.js";
import { useLocation } from 'react-router-dom';

import { useTranslation } from "react-i18next";
import { FormSection } from "../components/FormSection/FormSection.js";
import { FormCheckbox } from "../components/FormCheckbox/FormCheckbox.js";
import { FormTextarea } from "../components/FormTextarea/FormTextarea.js";
import { FormInput } from "../components/FormInput/FormInput.js";
import { FormInputFile } from "../components/FormInputFile/FormInputFile.js";
import { FormSelect } from "../components/FormSelect/FormSelect.js";

import checkSession from "../api/session/check.js";
import createTask from "../api/tasks/create.js";
import getCostTasks from "../api/costTasks/get.js";


export const TaskFormWildberriesPage = () => {
    const { t } = useTranslation();
    const location = useLocation();

    const [isOpenedOverlay, setIsOpenedOverlay] = useState(false);

    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    const [userData, setUserData] = useState()
    const [costTasks, setCostTasks] = useState();

    const [countForComplete, setCountForComplete] = useState(30)
    const [priceForComplete, setPriceForComplete] = useState(0)
    const [countPerOneComplete, setCountPerOneComplete] = useState(1)  

    const [isLoading, setIsLoading] = useState(true);

    function handleOverlayOpen() {
        setIsOpenedOverlay(!isOpenedOverlay);
    }

    const form_data = {
        title: "Шаблон ТЗ для площадки (WildBerries)",
        form_sections: [
            {
                title: "Выбор типа задания",
                items: [
                    {
                        type: "checkbox",
                        input_type: "radio",
                        isRequired: true,
                        name: "type",
                        value: "0",
                        content: "Выкуп"
                    },
                    {
                        type: "checkbox",
                        input_type: "radio",
                        isRequired: true,
                        name: "type",
                        value: "1",
                        content: "Кэшбек"
                    },
                    {
                        type: "checkbox",
                        input_type: "radio",
                        isRequired: true,
                        name: "type",
                        value: "2",
                        content: "Другое (избранное, подписка и остальное)"
                    },
                ]
            },
            {
                title: "Название задания",
                items: [
                    {
                        type: "input",
                        input_type: "text",
                        isRequired: true,
                        name: "title",
                        value: "Введите название задания:",
                        content: "Название задания"
                    }
                ]
            },
            {
                title: "Название товара (для себя)",
                items: [
                    {
                        type: "input",
                        input_type: "text",
                        isRequired: true,
                        name: "product_name",
                        value: "Введите название товара (для себя):",
                        content: "Название товара"
                    }
                ]
            },
            {
                title: "Краткое описание товара (для чего он нужен)",
                items: [
                    {
                        type: "textarea",
                        isRequired: true,
                        name: "product_description",
                        value: "Введите краткое описание товара (для чего он нужен)",
                        content: "краткое описание товара"
                    }
                ]
            },
            {
                title: "Номер артикула товара на ВБ",
                items: [
                    {
                        type: "input",
                        input_type: "num",
                        isRequired: true,
                        name: "wb_article",
                        value: "Введите номер артикула товара на ВБ",
                        content: "номер артикула товара на ВБ"
                    }
                ]
            },
            {

                title: "Ключевые слова для поиска через поисковую строку на сайте ВБ",
                hint: "Введите ключевые слова через запятую. Пример: тег1, тег2, тег3",
                items: [
                    {
                        type: "input",
                        input_type: "text",
                        isRequired: true,
                        name: "search_keywords",
                        value: "Введите ключевые слова для поиска через поисковую строку на сайте ВБ",
                        content: "ключевые слова"
                    }
                ]
            },
            {

                title: "Настройка фильтров",
                hint: "Введите фильтры через запятую. Пример: фильтр1, фильтр2, фильтр3",
                items: [
                    {
                        type: "textarea",
                        input_type: "text",
                        isRequired: true,
                        name: "filters",
                        value: "Введите фильтры",
                        content: "фильтры"
                    }
                ]
            },
            {

                title: "Настройка 'Сортировать по'",
                items: [
                    {
                        type: "checkbox",
                        input_type: "checkbox",
                        isRequired: true,
                        name: "sort_by",
                        value: "Популярности",
                        content: "Популярности"
                    },
                    {
                        type: "checkbox",
                        input_type: "checkbox",
                        isRequired: true,
                        name: "sort_by",
                        value: "Рейтингу",
                        content: "Рейтингу"
                    },
                    {
                        type: "checkbox",
                        input_type: "checkbox",
                        isRequired: true,
                        name: "sort_by",
                        value: "Цена по убыванию",
                        content: "Цена по убыванию"
                    },
                    {
                        type: "checkbox",
                        input_type: "checkbox",
                        isRequired: true,
                        name: "sort_by",
                        value: "Цена по возрастанию",
                        content: "Цена по возрастанию"
                    },
                    {
                        type: "checkbox",
                        input_type: "checkbox",
                        isRequired: true,
                        name: "sort_by",
                        value: "Скидке",
                        content: "Скидке"
                    },
                    {
                        type: "checkbox",
                        input_type: "checkbox",
                        isRequired: true,
                        name: "sort_by",
                        value: "Обновлению",
                        content: "Обновлению"
                    },
                    {
                        type: "checkbox",
                        input_type: "checkbox",
                        isRequired: true,
                        name: "sort_by",
                        value: "Без сортировки",
                        content: "Без сортировки"
                    },
                ]
            },
            {

                title: "Страница, на которой находится товар",
                items: [
                    {
                        type: "input",
                        input_type: "text",
                        isRequired: true,
                        name: "search_page",
                        value: "Введите cтраницу, на которой находится товар",
                        content: "страница, на которой находится товар"
                    },
                ]
            },
            {
                title: "Скриншот карточки товара на ВБ",
                items: [
                    {
                        type: "inputfile",
                        accept: "image/*",
                        isRequired: true,
                        name: "preview",
                        value: "Прикрепите скриншот карточки товара на ВБ",
                        content: "Скриншот карточки товара на ВБ"
                    },
                ]
            },
            {
                title: "Доп.названия для корректной идентификации",
                items: [
                    {
                        type: "input",
                        input_type: "text",
                        isRequired: true,
                        name: "additional_names",
                        value: "Название товара на сайте ВБ",
                        content: "Название товара"
                    },
                    {
                        type: "input",
                        input_type: "text",
                        isRequired: true,
                        name: "additional_names",
                        value: "Название бренда",
                        content: "Название бренда"
                    },
                    {
                        type: "input",
                        input_type: "text",
                        isRequired: true,
                        name: "additional_names",
                        value: "Название магазина/продавца",
                        content: "Название магазина/продавца"
                    },
                ]
            },
            {
                title: "Цена товара",
                items: [
                    {
                        type: "input",
                        input_type: "num",
                        isRequired: true,
                        name: "mp_price",
                        value: "Цена на МП",
                        content: "Цена на МП"
                    },
                    {
                        type: "input",
                        input_type: "num",
                        isRequired: true,
                        name: "price_for_you",
                        value: "Цена для вас",
                        content: "Цена для вас"
                    },
                    {
                        type: "textarea",
                        isRequired: false,
                        max_length: 600,
                        name: "comments",
                        value: "Комментарии",
                        content: "Комментарии"
                    },
                ]
            },
            {

                title: "Алгоритм при поиске",
                items: [
                    {
                        type: "textarea",
                        isRequired: false,
                        max_length: 600,
                        name: "search_algorithm",
                        value: "Алгоритм при поиске",
                        content: "Алгоритм при поиске"
                    },
                ]
            },
            {

                title: "Алгоритм после нахождения нужной карточки",
                items: [
                    {
                        type: "textarea",
                        isRequired: false,
                        max_length: 600,
                        name: "after_algorithm",
                        value: "Алгоритм после нахождения нужной карточки",
                        content: "Алгоритм после нахождения нужной карточки"
                    },
                ]
            },
            {

                title: "Действия после добавления товара в корзину",
                items: [
                    {
                        type: "input",
                        input_type: "text",
                        isRequired: true,
                        max_length: 600,
                        name: "do_after",
                        value: "Действия после добавления товара в корзину",
                        content: "Действия после добавления товара в корзину"
                    },
                    {
                        type: "input",
                        input_type: "num",
                        isRequired: true,
                        name: "time_before_buy",
                        value: "Указать через сколько времени можно выкупить (в минутах)",
                        content: "Время в минутах"
                    },
                ]
            },
            {
                title: "Избранное/подписка/вопросы - указать, куда поставить Лайки",
                items: [
                    {
                        type: "select",
                        isMultiple: true,
                        isRequired: true,
                        max_length: 600,
                        name: "likes",
                        content: "Лайки",
                        items: [
                            {
                                value: "Подписка",
                                content: "Подписка"
                            },
                            {
                                value: "Магазин",
                                content: "Магазин"
                            },
                            {
                                value: "Бренд",
                                content: "Бренд"
                            },
                            {
                                value: "Товар",
                                content: "Товар"
                            },
                            {
                                value: "Отзывы",
                                content: "Отзывы"
                            },
                            {
                                value: "Вопросы",
                                content: "Вопросы"
                            },
                        ]
                    }
                ]
            },
            {
                title: "Пожелания к вопросу",
                items: [
                    {
                        type: "input",
                        input_type: "text",
                        isRequired: false,
                        max_length: 600,
                        name: "question_suggesions",
                        value: "Укажите пожелания для написания вопроса",
                        content: "Пожелания к вопросу"
                    },
                ]
            },
            {
                title: "Указать адрес доставки (свой адрес)",
                items: [
                    {
                        type: "input",
                        input_type: "text",
                        isRequired: true,
                        max_length: 600,
                        name: "delivery_address",
                        value: "Укажите адрес доставки",
                        content: "Адрес доставки"
                    },
                ]
            },
            {

                title: "Отзыв о товаре",
                items: [
                    {
                        type: "input",
                        input_type: "num",
                        isRequired: true,
                        max_length: 600,
                        name: "review_time",
                        value: "Через сколько дней после получения оставить положительный отзыв",
                        content: "Количество дней"
                    },
                    {
                        type: "textarea",
                        isRequired: true,
                        max_length: 600,
                        name: "review_suggestions",
                        value: "Пожелания для отзыва",
                        content: "Пожелания для отзыва"
                    },
                    {
                        type: "checkbox",
                        input_type: "checkbox",
                        isRequired: true,
                        max_length: 600,
                        name: "review_photo_option",
                        value: "C фото",
                        content: "C фото"
                    },
                ]
            },
            {

                title: "Период действия ТЗ для выкупа",
                items: [
                    {
                        type: "input",
                        input_type: "date",
                        isRequired: true,
                        max_length: 600,
                        name: "period_active",
                        value: "Дата окончания",
                        content: "Дата окончания"
                    },
                ]
            },
            {
                title: "Другие пожелания по заданию",
                items: [
                    {
                        type: "textarea",
                        isRequired: true,
                        max_length: 600,
                        name: "other_suggestions",
                        value: "Пожелания для задания",
                        content: "Пожелания для задания"
                    },
                ]
            },
        ]
    }

    useEffect(() => {
        const checkAuth = async () => {
            try {
                if (token) {
                    var response = await checkSession(token);
                    setUserData(response.user)
                    // Если авторизация успешна, пользователь остается на странице.
                    setIsLoading(false);
                } else {
                    // Если необходимые параметры отсутствуют, перенаправляем на /error-page.
                }
            } catch (error) {
                console.error('Ошибка проверки авторизации:', error);
                // Обработка ошибки при выполнении запроса.
                // В данном примере просто перенаправляем на /error-page в случае ошибки.
            }
        };

        const fetchCostTasks = async () => {
            try {
                if (token) {
                    var response = await getCostTasks({}, token);
                    setCostTasks(response)
                    // Если авторизация успешна, пользователь остается на странице.
                    setIsLoading(false);
                } else {
                    // Если необходимые параметры отсутствуют, перенаправляем на /error-page.
                }
            } catch (error) {
                console.error('Ошибка получения стоимости размещения заданий:', error);
                // Обработка ошибки при выполнении запроса.
                // В данном примере просто перенаправляем на /error-page в случае ошибки.
            }
        };

        checkAuth();
        fetchCostTasks();
    }, []);

    async function handleSubmit(e) {
        e.preventDefault();
        const formData = new FormData(e.target);
    
        try {
            // Проверяем, что выбран файл
            if (!formData.get('preview')) {
                console.error('Выберите файл для загрузки');
                return;
            }
    
            // Проверяем, что выбранный файл является изображением
            const file = formData.get('preview');
            if (file.type.split('/')[0] !== 'image') {
                console.error('Выберите изображение');
                return;
            }
    
            // Отправляем данные на сервер
            await createTask(formData, token);
            // Успешная отправка задачи, обновление UI или перенаправление на другую страницу
        } catch (error) {
            console.error('Ошибка при создании задачи:', error);
            // Обработка ошибки при создании задачи
        }
    }
    

    return (
        <>
            <div className="form-wrapper">
                <form className="form" onSubmit={handleSubmit}>
                    <div className="container">
                        <div className="form-header">
                            <h1 className="text-h1">{form_data.title}</h1>
                        </div>
                        <div className="form-body">
                            {
                                form_data.form_sections.map((form_section, index) => (
                                    <FormSection title={form_section.title} hint={form_section.hint} current_step={index + 1} count_steps={form_data.form_sections.length} key={index}>
                                        {form_section.items.map((section_item, index) => {
                                            switch (section_item.type) {
                                                case 'checkbox':
                                                    return <FormCheckbox key={index} type={section_item.type} input_type={section_item.input_type} name={section_item.name} value={section_item.value} content={section_item.content}></FormCheckbox>;
                                                case 'input':
                                                    return <FormInput key={index} type={section_item.type} isRequired={section_item.isRequired} input_type={section_item.input_type} name={section_item.name} value={section_item.value} content={section_item.content}></FormInput>;
                                                case 'textarea':
                                                    return <FormTextarea key={index} type={section_item.type} isRequired={section_item.isRequired} max_length={section_item.max_length} name={section_item.name} value={section_item.value} content={section_item.content}></FormTextarea>;
                                                case 'inputfile':
                                                    return <FormInputFile key={index} type={section_item.type} isRequired={section_item.isRequired} max_length={section_item.max_length} name={section_item.name} value={section_item.value} content={section_item.content}></FormInputFile>;
                                                case 'select':
                                                    return <FormSelect key={index} isMultiple={section_item.isMultiple} isRequired={section_item.isRequired} name={section_item.name} items={section_item.items} content={section_item.content}></FormSelect>;
                                                default:
                                                    return null;
                                            }
                                        })}
                                    </FormSection>
                                ))
                            }
                            <FormSection title="Разрешенное количество человек для выкупа/кэшбека" current_step={21} count_steps={22}>
                                <div className="input-wrapper">
                                    <label className="label" htmlFor={""}>
                                        {"Разрешенное количество человек для выкупа/кэшбека"}
                                        {true ? <span style={{color: "red"}}>*</span> : null}
                                    </label>
                                    <input className="input" name={"count_for_complete"} id={"count_for_complete"} minLength={1000} onChange={(e) => {setCountForComplete(e.target.value)}} value={countForComplete} required={true} type={"number"} placeholder={"Количество человек"} />
                                </div>
                            </FormSection>
                            <FormSection title="Разрешенное количество штук товара для выкупа/кэшбека одним человеком" current_step={21} count_steps={22}>
                                <div className="input-wrapper">
                                    <label className="label" htmlFor={""}>
                                        {"Разрешенное количество человек для выкупа/кэшбека"}
                                        {true ? <span style={{color: "red"}}>*</span> : null}
                                    </label>
                                    <input className="input" name={"count_for_complete"} id={"count_for_complete"} minLength={1000} onChange={(e) => {setCountForComplete(e.target.value)}} value={countForComplete} required={true} type={"number"} placeholder={"Количество человек"} />
                                </div>
                            </FormSection>
                            <FormSection title="Цена за выполнение задания" current_step={21} count_steps={22}>
                                <div className="input-wrapper">
                                    <label className="label" htmlFor={""}>
                                        {"Цена за выполнение задания"}
                                        {true ? <span style={{color: "red"}}>*</span> : null}
                                    </label>
                                    <input className="input" name={"price_for_complete"} id={"price_for_complete"} minLength={1000} onChange={(e) => {setPriceForComplete(e.target.value)}} value={priceForComplete} required={true} type={"number"} placeholder={"Цена"} />
                                </div>
                            </FormSection>
                        </div>
                        <div className="form-footer">
                            <table className="price-table">
                                <tbody>
                                    <tr>
                                        <td>Стоимость выплат:</td>
                                        <td id="payments-cost">{countForComplete * priceForComplete} руб.</td>
                                    </tr>
                                    {userData && costTasks ?
                                        <tr>
                                            <td>Стоимость размещения:</td>
                                            <td id="place-cost">{ userData?.rating_paid >= userData?.rating_completed ? 0 : costTasks[0].cost} руб.</td>
                                        </tr>    
                                    : ""
                                    }
                                    <tr>
                                        <td>Итого:</td>
                                        <td id="summary-cost">0 рублей</td>
                                    </tr>
                                </tbody>
                            </table>
                            <button className="primary-btn" id="wildberries_form_submit">Отправить</button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};
