import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import io from "socket.io-client"; // Импортируем клиентскую часть Socket.IO

import { FormSection } from "../components/FormSection/FormSection.js";
import { FormCheckbox } from "../components/FormCheckbox/FormCheckbox.js";
import { FormTextarea } from "../components/FormTextarea/FormTextarea.js";
import { FormInput } from "../components/FormInput/FormInput.js";
import { FormInputFile } from "../components/FormInputFile/FormInputFile.js";
import { FormSelect } from "../components/FormSelect/FormSelect.js";

import getChatMessages from "../api/chat/getMessages.js";
import getChat from "../api/chat/getOne.js";
import formatDate from "../utils/formatDate.js";

// const socket = io('http://localhost:8080'); // Подключаемся к сокет серверу

export const ChatPage = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const [isOpenedOverlay, setIsOpenedOverlay] = useState(false);
    const params = new URLSearchParams(location.search);

    const { chat_id } = useParams();
    const [currentChat, setCurrentChat] = useState({}); 
    const [chatMessages, setChatMessages] = useState([])

    const session = params.get('session');

    useEffect(() => {
        const fetchChat = async () => {
            try {
                const chat = await getChat({ chat_id: chat_id }, session);
                setCurrentChat(chat[0]);
            } catch (error) {
                console.error("Ошибка при получении чата:", error.message);
            }
        };

        const fetchChatMessages = async () => {
            try {
                const chatMessages = await getChatMessages({ chat_id: chat_id }, session);
                setChatMessages(chatMessages);

                // Подписываемся на событие "messageReceived" для получения новых сообщений
                // socket.on('messageReceived', (data) => {
                //     setChatMessages(prevMessages => [...prevMessages, data]);
                // });
            } catch (error) {
                console.error("Ошибка при получении списка сообщений:", error.message);
            }
        };
        
        fetchChat();
        fetchChatMessages();
    }, [session, chat_id]); 

    // const sendMessage = (message) => {
    //     // Отправляем сообщение на сервер через сокеты
    //     socket.emit('sendMessage', { chat_id: chat_id, message: message });
    // };

    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     const message = event.target.elements.message_input.value;
    //     sendMessage(message);
    //     event.target.reset();
    // };

    return (
        <div className="chat-layout">
            <header className="header">
                <div className="container">
                    <Link className="back-btn" href="/chat-list"></Link>
                    <h1 className="chat-title">Чат | {currentChat?.title}</h1>
                </div>
            </header>
            <main>
                <div className="chat-wrapper">
                    {chatMessages ? 
                    <ul className="messages-list" id="messages-list">
                        {chatMessages.map((message, index) => (
                            <li key={index} className={`messages-list__item ${message.owner_id === 1 ? "own" : ""}`}>
                                <p className="messages-list__p">{message.text}</p>
                                <span className="messages-list__date">{formatDate(message.date)}</span>
                            </li>
                        ))}
                    </ul> : ''}
                </div>
            </main>
            <footer className="footer">
                <div className="container">
                    <form className="chat-input-form" id="chat-input-form" >
                        <textarea className="input" id="message_input" name="message_input" placeholder="Введите сообщение..." cols="30" rows="10"></textarea>
                        <button className="primary-btn" type="submit">Отправить</button>
                    </form>
                </div>
            </footer>
        </div>
    );
};
