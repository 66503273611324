const formatDate = (isoDateString) => {
    // Создаем объект Date из строки ISO 8601
    const date = new Date(isoDateString);

    // Получаем значения часов, минут и секунд
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const seconds = date.getUTCSeconds();

    // Получаем значения дня, месяца и года
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1; // Прибавляем 1, так как месяцы в JavaScript начинаются с 0
    const year = date.getUTCFullYear();

    // Форматируем значения, добавляя нули перед числами, если они составляют одну цифру
    const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    const formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year.toString()}`;

    // Возвращаем отформатированную строку
    return `${formattedTime} - ${formattedDate}`;
};

export default formatDate;
