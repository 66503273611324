import React from "react";
import { styles } from "./FormSection.css"; // Подключение стилей может потребовать дополнительной настройки
import { Link, useLocation } from "react-router-dom";

export const FormSection = (props) => {
  return (
    <div className="form-section">
      <div className="form-section__header">
        <h2 className="form-section__heading">{props.title}</h2>
        <div className="form-section__step">
          <span>Шаг {props.current_step} </span>
          <span>из {props.count_steps}</span>
        </div>
      </div>
      <div className="form-section__content">
        {props.hint ? (
          <div className="hint">
            <div className="hint-heading">Подсказка</div>
            <div className="hint-content">
              <p className="hint__p">
                {props.hint}
              </p>
            </div>
          </div>
        ) : null}
        {props.children}
      </div>
    </div>
  );
};
