import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { FormSection } from "../components/FormSection/FormSection.js";
import { FormCheckbox } from "../components/FormCheckbox/FormCheckbox.js";
import { FormTextarea } from "../components/FormTextarea/FormTextarea.js";
import { FormInput } from "../components/FormInput/FormInput.js";
import { FormInputFile } from "../components/FormInputFile/FormInputFile.js";
import { FormSelect } from "../components/FormSelect/FormSelect.js";

export const PaymentFailedPage = () => {
    const { t } = useTranslation();

    const [isOpenedOverlay, setIsOpenedOverlay] = useState(false);

    function handleOverlayOpen() {
        setIsOpenedOverlay(!isOpenedOverlay);
    }

    return (
        <>
            <div class="form-wrapper">
                <form class="form form-payment" method="post" encType="multipart/form-data">
                    <div class="container">
                        <h1 class="text-h1">Ошибка оплаты</h1>
                        <div class="primary-btn">Закрыть окно</div>
                    </div>
                </form>
            </div>
        </>
    );
};
