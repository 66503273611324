import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { FormSection } from "../components/FormSection/FormSection.js";
import { FormCheckbox } from "../components/FormCheckbox/FormCheckbox.js";
import { FormTextarea } from "../components/FormTextarea/FormTextarea.js";
import { FormInput } from "../components/FormInput/FormInput.js";
import { FormInputFile } from "../components/FormInputFile/FormInputFile.js";
import { FormSelect } from "../components/FormSelect/FormSelect.js";

export const PaymentFormPage = () => {
    const { t } = useTranslation();

    const [isOpenedOverlay, setIsOpenedOverlay] = useState(false);

    function handleOverlayOpen() {
        setIsOpenedOverlay(!isOpenedOverlay);
    }

    const form_data = {
        title: "Введите сумму пополнения баланса:",
        form_sections: [
            {
                title: "Заполните форму пополнения: ",
                items: [
                    {
                        type: "input",
                        input_type: "text",
                        name: "",
                        content: "Введите сумму пополнения баланса:",
                        isRequired: true
                    }
                ]
            }
        ]
    }

    return (
        <>
            <div class="form-wrapper">
                <form class="form form-payment" method="post" encType="multipart/form-data">
                    <div class="container">
                        <div class="form-header">
                            <h1 class="text-h1">{form_data.title}</h1>
                        </div>
                        <div class="form-body">
                            {
                                form_data.form_sections.map((form_section) => (
                                    <FormSection title={form_section.title} current_step={form_section.id} count_steps={form_data.form_sections.length} key={form_section.id}>
                                        {form_section.items.map((section_item, index) => {
                                            switch (section_item.type) {
                                                case 'checkbox':
                                                    return <FormCheckbox key={index} type={section_item.type} input_type={section_item.input_type} name={section_item.name} value={section_item.value} content={section_item.content}></FormCheckbox>;
                                                case 'input':
                                                    return <FormInput key={index} type={section_item.type} isRequired={section_item.isRequired} input_type={section_item.input_type} name={section_item.name} value={section_item.value} content={section_item.content}></FormInput>;
                                                case 'textarea':
                                                    return <FormTextarea key={index} type={section_item.type} isRequired={section_item.isRequired} max_length={section_item.max_length} name={section_item.name} value={section_item.value} content={section_item.content}></FormTextarea>;
                                                case 'inputfile':
                                                    return <FormInputFile key={index} type={section_item.type} isRequired={section_item.isRequired} max_length={section_item.max_length} name={section_item.name} value={section_item.value} content={section_item.content}></FormInputFile>;
                                                case 'select':
                                                    return <FormSelect key={index} isMultiple={section_item.isMultiple} isRequired={section_item.isRequired} name={section_item.name} items={section_item.items} content={section_item.content}></FormSelect>;
                                                default:
                                                    return null;
                                            }
                                        })}
                                    </FormSection>
                                ))
                            }
                        </div>
                        <div class="form-footer">
                            <div class="price-wrapper">
                                <span>К зачислению: </span>
                                <span id="task-summary">0 руб.</span>
                            </div>
                            <button class="primary-btn" id="payment_form_submit">Перейти к оплате</button>
                            <p class="form-disclaimer">
                                Ваши персональные данные будут использоваться для обработки заказа, подробнее в <a href="/uploads/oferta_235101823565.docx">оферта</a>.
                            </p>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};
