import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';

import { FormSection } from "../components/FormSection/FormSection.js";
import { FormCheckbox } from "../components/FormCheckbox/FormCheckbox.js";
import { FormTextarea } from "../components/FormTextarea/FormTextarea.js";
import { FormInput } from "../components/FormInput/FormInput.js";
import { FormInputFile } from "../components/FormInputFile/FormInputFile.js";
import { FormSelect } from "../components/FormSelect/FormSelect.js";
import getChats from "../api/chat/getAll.js";

export const ChatListPage = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const [isOpenedOverlay, setIsOpenedOverlay] = useState(false);

    // Массив чатов (заменяем $results->data)
    const [chatList, setChatList] = useState([])

    const params = new URLSearchParams(location.search);
    const session = params.get('session');

    useEffect(() => {
        const fetchChats = async () => {
            try {
                // Запрос к API для получения списка чатов
                const chats = await getChats({}, session);
                setChatList(chats); // Установка полученных чатов в состояние chatList
            } catch (error) {
                console.error("Ошибка при получении списка чатов:", error.message);
            }
        };

        fetchChats(); // Вызов функции для получения чатов при загрузке страницы
    }, [session]); // Зависимости: user_id и session


    return (
        <div className="page-wrapper">
            <header className="header">
                <div className="container">
                    <h1 className="text-h1">Список чатов</h1>
                </div>
            </header>
            <main>
                <div className="chats-wrapper">
                    {!chatList || chatList?.length <= 0 ? (
                        <div className="empty-list">Список пуст</div>
                    ) : (
                        <ul className="chats-list">
                            {chatList.map((chat, index) => (
                                <li key={index} className="chats-list__item">
                                    <div className="chats-list__wrapper">
                                        <h2 className="chats-list__h2">{chat.title}</h2>
                                    </div>
                                    <a
                                        className="primary-btn"
                                        href={`/chat/${chat.chat_id}?session=${session}`} // Добавлены параметры user_id и session в ссылку
                                    >
                                        Перейти в чат
                                    </a>
                                </li>
                            ))}
                        </ul>
                    )}

                </div>
            </main>
            <footer className="footer">
                <div className="container">
                    <div className="pagination">{/* Вставьте здесь пагинацию */}</div>
                </div>
            </footer>
        </div>
    );
};
