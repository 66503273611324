const getChatMessages = async (params, token) => {
    const apiUrl = `${process.env.REACT_APP_DOMAIN_URL}/api/chat/getMessages`;


    try {
        const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": token
            },
            body: JSON.stringify(params),
        });

        if (response.ok) {
            // Получаем данные из ответа
            const data = await response.json();

            return data;
        } else {
            // Обработка ошибки
            throw new Error(`Error get chat messages: ${response.statusText}`);
        }
    } catch (error) {
        // Обработка ошибок сети или других проблем
        console.error(`Error: ${error.message}`);
    }
};

export default getChatMessages;
