import React, { useState, useEffect } from "react";
import getTasks from "../api/tasks/get.js";
import startTask from "../api/tasks/start.js";
import { useLocation } from 'react-router-dom';

import { useTranslation } from "react-i18next";

import formatDate from "../utils/formatDate.js"

export const TaskListAssignPage = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const [isOpenedOverlay, setIsOpenedOverlay] = useState(false);

  function handleOverlayOpen() {
    setIsOpenedOverlay(!isOpenedOverlay);
  }

  const [taskList, setTaskList] = useState([]);
  const params = new URLSearchParams(location.search);
  const session = params.get('session');


  const fetchStartTask = async (task_id) => {
    try {
      const response = await startTask({ task_id: task_id }, session); // Запрос к API для начала задачи
      if(response) {
        window.close();
      }
    } catch (error) {
      console.error("Ошибка при начале задачи:", error.message);
    }
  };
  
  useEffect(() => {
    const fetchTasks = async () => {
        try {
            const tasks = await getTasks(); // Запрос к API для получения задач
            setTaskList(tasks.data); // Установка полученных задач в состояние taskList
        } catch (error) {
            console.error("Ошибка при получении задач:", error.message);
        }
    };

    fetchTasks(); // Вызов функции для получения задач при загрузке страницы
}, []); // Пустой массив зависимостей, чтобы вызвать useEffect только один раз при загрузке страницы

  return (
    <>
      <div className="TaskListPage">
        <header className="header">
          <div className="container">
            <h1 className="text-h1">Список заданий</h1>
            <div className="flex-wrapper">
              <select id="sortSelect">
                <option value="popularity">Популярности</option>
                <option value="priceAsc">Цене (Возрастание)</option>
                <option value="priceDesc">Цене (Убывание)</option>
                <option value="new">Новые</option>
              </select>
              <div className="primary-btn" onClick={handleOverlayOpen}>
                Фильтры
              </div>
            </div>
          </div>
        </header>
        <main>
          <div className="tasks-wrapper">
            {taskList?.length > 0 ? (
              <ul className="task-list">
                {taskList.map((taskItem, index) => (
                  <li className="task-list__item" key={index}>
                    <div className="task-list__preview">
                      <img src={process.env.REACT_APP_DOMAIN_URL + taskItem.preview} alt="" />
                    </div>
                    <div className="task-list__wrapper">
                      <h4 className="task-list__title">{taskItem.title}</h4>
                      <div className="task-list__tags">
                        <p>
                          <b>Площадка:</b> {taskItem.place}, 
                          <b>Тип задания:</b> {taskItem.data.type}, 
                          <b>ID Создателя:</b> {taskItem.owner_id}
                        </p>
                      </div>
                      <p className="task-list__description">
                        {taskItem.description}
                      </p>
                      <div className="task-list__date">
                        Дата добавления: {formatDate(taskItem.createdAt)}
                      </div>
                      <p className="task-list__price">
                        Цена на маркетплейсе - {taskItem.data.price_mp} ₽,
                        Цена для вас - {taskItem.data.price_for_you} ₽.
                      </p>
                      <p className="task-list__price">
                        <b>Плата за выполнение</b> - {taskItem.price_for_complete} ₽
                      </p>
                    </div>
                    <div className="task-list__btn" onClick={() => fetchStartTask(taskItem.id)}>Начать выполнение</div>
                  </li>
                ))}
              </ul>
            ) : (
              <div className="empty-list">Список пуст</div>
            )}
          </div>
        </main>
        <footer className="footer">
          <div className="container">
            <div className="pagination"></div>
          </div>
        </footer>
        <div className={"overlay" + (isOpenedOverlay ? " opened" : '')} >
          <div className="container">
            <div className={"filter-menu" + (isOpenedOverlay ? " opened" : '')} >
              <div className="filter-menu__header">
                <h2 className="text-h2">Фильтры</h2>
                <div className="overlay-item__close-btn" onClick={handleOverlayOpen}>
                  <span></span>
                  <span></span>
                </div>
              </div>
              <div className="filter-menu__content">
                <div className="filter-section opened">
                  <div className="filter-section__header">
                    <h3 className="text-h3">Тип</h3>
                    <img src="" alt="" />
                  </div>
                  <div className="filter-section__content">
                    <div className="filter-section__item">
                      <div className="checkbox-wrapper">
                        <input className="checkbox" type="checkbox" name="type" id="" value="" />
                        <label htmlFor=""></label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="filter-section opened">
                  <div className="filter-section__header">
                    <h3 className="text-h3">Площадка</h3>
                    <img src="" alt="" />
                  </div>
                  <div className="filter-section__content">
                    <div className="filter-section__item">
                      <div className="checkbox-wrapper">
                        <input className="checkbox" type="checkbox" name="place" id="" value="" />
                        <label htmlFor=""></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-section__footer">
                <div className="primary-btn" id="filters-apply">Применить</div>
                <div className="primary-btn" id="filters-reset">Сбросить</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
