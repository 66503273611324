import React from 'react';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { Routes, Route, Link } from 'react-router-dom';

import { TaskFormWildberriesPage } from './pages/TaskFormWildberriesPage';

import { TaskListAssignPage } from './pages/TaskListAssignPage';
import { PaymentFormPage } from './pages/PaymentFormPage';
import { PaymentSuccessPage } from './pages/PaymentSuccessPage';
import { PaymentFailedPage } from './pages/PaymentFailedPage';
import { ChatListPage } from './pages/ChatListPage';
import { ChatPage } from './pages/ChatPage';
import { Notfoundpage } from './pages/Notfoundpage';

import ResetCSS from './styles/reset.css';
import Fonts from './styles/fonts.css';
import Styles from './styles/style.css';

function App() {
  const { t } = useTranslation();

  const location = useLocation();

  const [title, setTitle] = useState("Default Title");

  useEffect(() => {
    switch(location.pathname) {
      case '/': {
        document.title = "Help Market Place";
        break;
      }
      case '/task-list-assign': {
        document.title = "Список заданий";
        break;
      }
      case '/task-form-wildberries': {
        document.title = "Форма задания (Wildberries)";
        break;
      }
      case '/payment-form': {
        document.title = "Пополнить баланс";
        break;
      }
      case '/payment-failed': {
        document.title = "Ошибка пополнения";
        break;
      }
      case '/chat-list': {
        document.title = "Список чатов";
        break;
      }
      case '/chat/': {
        document.title = "Чат";
        break;
      }
      case '/error-page': {
        document.title = "Ошибка";
        break;
      }
      default: {
        document.title = 'Help Market Place';
        break;
      }
    }
  }, [location, setTitle]);
  
  return (
    <Routes>
      <Route path='/task-form-wildberries' element={<TaskFormWildberriesPage/>} />
      <Route path='/task-list-assign' element={<TaskListAssignPage/>} />
      <Route path='/payment-form' element={<PaymentFormPage/>} />
      <Route path='/payment-success' element={<PaymentSuccessPage/>} />
      <Route path='/payment-failed' element={<PaymentFailedPage/>} />
      <Route path='/chat-list' element={<ChatListPage/>} />
      <Route path='/chat/:chat_id' element={<ChatPage/>} />
      <Route path='/error-page' element={<Notfoundpage/>} />
    </Routes>
  );
}

export default App;
