import React from "react";
import "./FormInput.css"; // Подключение стилей может потребовать дополнительной настройки
import { Link, useLocation } from "react-router-dom";

export const FormInput = (props) => {
  return (
    <div className="input-wrapper">
      <label className="label" htmlFor={props.name+'-'+props.value}>
        {props.content}
        {props.isRequired ? <span style={{color: "red"}}>*</span> : null}
      </label>
      <input className="input" name={props.name} id={props.name+'-'+"test"}  required={props.isRequired} type={props.input_type} placeholder={props.content} />
    </div>
  );
};
