import React from "react";
import "./FormTextarea.css"; // Подключение стилей может потребовать дополнительной настройки
import { Link, useLocation } from "react-router-dom";

export const FormTextarea = (props) => {
  return (
    <div className="input-wrapper">
      <label className="label" htmlFor={props.name+'-'+props.value}>
        {props.content}
        {props.isRequired ? <span style={{color: "red"}}>*</span> : null}
      </label>
      <textarea className="textarea" maxLength={props.max_length} required={props.isRequired} name={props.name}  placeholder={props.content} id={props.name+'-'+props.value} cols="30" rows="10"></textarea>
    </div>
  );
};
