import React, { useState } from "react";
import "./FormInputFile.css"; // Подключение стилей может потребовать дополнительной настройки

export const FormInputFile = (props) => {
  const [filePreview, setFilePreview] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      setFilePreview(URL.createObjectURL(file));
    } else {
      setFilePreview(null); // Если файл не картинка, очищаем превью
    }
  };

  return (
    <div className="input-wrapper">
      <label className="label" htmlFor={props.name + '-' + props.value}>
        {props.content}
        {props.isRequired ? <span style={{ color: "red" }}>*</span> : null}
      </label>
      <input 
        className="input" 
        required={props.isRequired} 
        name={props.name} 
        id={props.name + '-' + props.value} 
        maxLength={props.max_length} 
        accept={props.accept} 
        type="file" 
        placeholder={props.content}
        onChange={handleFileChange} // Добавляем обработчик события
      />
      {filePreview && <img src={filePreview} alt="Preview" className="preview-image" />}
    </div>
  );
};
