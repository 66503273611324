import React from "react";
import { styles } from "./FormCheckbox.css"; // Подключение стилей может потребовать дополнительной настройки
import { Link, useLocation } from "react-router-dom";

export const FormCheckbox = (props) => {
  return (
    <div className="checkbox-wrapper">
      <input className="checkbox" required={props.isRequired} type={props.input_type} name={props.name} value={props.value} id={props.name+"-"+props.value}/>
      <label className="label" htmlFor={props.name+"-"+props.value}>{props.content}</label>
    </div>
  );
};
